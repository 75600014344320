<script setup>
import Homeheader from "@/components/Homeheader";
import { useMessage } from "@/hooks/useMessage";
import { ref, reactive } from "vue";
import { http } from "@/utils/http";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore();
// const centerDialogVisible = ref(true); // 普通解析
const centerDialogVisible = ref(false); // 普通解析
const centerDialogVisible1 = ref(false); // 二次解析
const parseInfo = reactive({
  downloadPath: "",
  url: "",
  down_item: [],
  down_type: "",
  parseFlag: false,
});
let textCopy = ref();
// 点击复制文本
const handCopy = () => {
  const text = parseInfo.downloadPath; // 复制文本内容
  const input = textCopy.value;
  input.value = text; // 修改input的内容
  input.select(); // 选中文本
  document.execCommand("copy"); // 浏览器复制
  useMessage(
    "success",
    "链接复制成功,请在浏览器新标签页地址栏粘贴复制的链接进行手动下载"
  );
};
// 一次解析
const handParse = async () => {
  if (parseInfo.parseFlag) {
    useMessage("error", "解析间隔为10秒,请10秒钟以后再试!");
    return;
  }
  if (parseInfo.url == "") {
    useMessage("error", "请输入素材链接");
    return;
  }
  if (!(parseInfo.url.indexOf("http") != -1)) {
    useMessage("error", "请复制完整的链接");
    return;
  }
  // 发送请求
  try {
    const response = await http.post(
      "/v1/user/parse",
      {
        link: parseInfo.url,
      },
      { headers: { userToken: localStorage.getItem("userToken") } }
    );
    const res = response.data;
    // 解析失败
    if (res.code != 200) {
      useMessage("error", res.message);
      return;
    }
    if (res.data.down_type === "2") {
      // 需要二次解析
      centerDialogVisible1.value = true;
      parseInfo.down_item = res.data.down_item;
    } else {
      // 更新次数
      getAuth();
      // 解析成功
      useMessage("success", res.message);
      // 设置10秒内不能重复解析
      parseInfo.parseFlag = true;
      setTimeout(function () {
        parseInfo.parseFlag = false;
      }, 10000); // 延迟时间以毫秒为单位
      parseInfo.downloadPath = res.data.down_url;
      centerDialogVisible.value = true;

      // store.state.userInfo.forEach((element) => {
      //   if (element.id == res.data.site) {
      //     element.download_num = res.data.sur;
      //   }
      // });
    }
  } catch (error) {
     useMessage("error", "请求超时,请重新尝试解析");
    // 在这里处理错误，例如显示一个友好的提示给用户
    console.error("发生错误:", error);
    // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
  }
};

// 二次解析
const handParse1 = async (file_type) => {
  if (parseInfo.parseFlag) {
    useMessage("error", "解析间隔为10秒,请10秒钟以后再试!");
    return;
  }
  // 发送请求
  http
    .post(
      "/v1/user/parseItem",
      {
        link: parseInfo.url,
        downType: file_type,
      },
      { headers: { userToken: localStorage.getItem("userToken") } }
    )
    .then((res) => {
      // 解析失败
      if (res.data.code != 200) {
        useMessage("error", res.data.message);
        return;
      }
      // 更新次数
      getAuth();
      centerDialogVisible1.value = false;
      // 解析成功
      useMessage("success", res.data.message);
      // 设置10秒内不能重复解析
      parseInfo.parseFlag = true;
      setTimeout(function () {
        parseInfo.parseFlag = false;
      }, 10000); // 延迟时间以毫秒为单位
      parseInfo.downloadPath = res.data.data.down_url;
      centerDialogVisible.value = true;
      ////
      // const link = document.createElement("a"); //创建下载a标签
      // link.href = res.data.data.down_url;
      // link.style.display = "none"; //默认隐藏元素
      // link.target = "_blank";
      // document.body.appendChild(link); // body中添加元素
      // link.click();
      // document.body.removeChild(link); //下载完成后移除元素
    })
    .catch((error) => {
      useMessage("error", "请求超时,请重新尝试解析");
      console.error("发生错误:", error);
      // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
    });
};

const getAuth = () => {
  http
    .get("/v1/user/getAuth", {
      headers: {
        userToken: localStorage.getItem("userToken"),
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.code !== 200) {
        useMessage("error", res.message);
        return;
      }
      store.commit("addname", res.data.username);
      store.commit("addUserInfo", res.data.userInfo);
      store.commit("addNotice", res.data.notice);
      store.commit("addEmail", res.data.email);
    })
    .catch((error) => {
      // 在这里处理错误，例如显示一个友好的提示给用户
      console.error("发生错误:", error);
      // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
    });
};

// 动态创建下载标签
const handDownload = () => {
  const link = document.createElement("a"); //创建下载a标签
  link.href = parseInfo.downloadPath;
  link.style.display = "none"; //默认隐藏元素
  link.target = "_blank";
  document.body.appendChild(link); // body中添加元素
  link.click();
  document.body.removeChild(link); //下载完成后移除元素
};
</script>

// 素材解析 =================
<template>
  <el-col>
    <homeheader height-class="h-[280px]">
      <template #title> 素材解析 </template>
      <template #content>
        <div>
          <input
            v-model="parseInfo.url"
            placeholder="请输入素材链接"
            :class="
              $store.state.userInfo.length
                ? 'mt-1 pl-5 block w-full border-none bg-gray-100 h-10 rounded-xl shadow-lg hover:bg-blue-100 focus:outline-none  focus:bg-blue-100 focus:ring-0'
                : 'mt-1 pl-5 block w-full border-none bg-gray-100 h-10 rounded-xl shadow-lg focus:bg-blue-100 focus:outline-none focus:ring-0 cursor-not-allowed'
            "
            :disabled="$store.state.userInfo.length ? false : true"
          />
          <button
            :class="
              $store.state.userInfo.length
                ? 'mt-6 bg-blue-500 w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-102'
                : 'mt-6 bg-blue-500 w-full py-2 rounded-xl text-white shadow-xl cursor-not-allowed'
            "
            :disabled="$store.state.userInfo.length ? false : true"
            @click="handParse"
          >
            解析素材
          </button>
        </div>
        <input ref="textCopy" value="" style="opacity: 0; position: absolute" />
      </template>
      <!-- <template #subtitle> {{ animal.price }} </template>
      <template #badge> {{ animal.unit }} </template>
      <template #banner> {{ animal.category }} </template>
      <template #description> {{ animal.description }} </template> -->
    </homeheader>
  </el-col>
  <el-dialog
    v-model="centerDialogVisible1"
    title="选择下载类型"
    width="20%"
    align-center
    style="margin-top: 300px"
  >
    <div class="mb-0">
      <span
        >点击下面按钮
        <span class="text-xl text-red-500">选择需要的下载格式</span>
        进行下载
      </span>
    </div>

    <!-- <div class="mb-6">
      <span
        >如果点击下载按钮
        <span class="text-xl text-red-500">下载失败(或者出现403)</span>
        按照下面方法操作:
      </span>
    </div>
    <span class="text-xl"
      >点击复制链接按钮,打开<span class="text-red-500">(新的标签页)</span
      >粘贴到地址栏进行下载
    </span>

    <div class="mt-6">
      <span>（一定要打开浏览器新的标签页） </span>
    </div> -->
    <template #footer>
      <span class="dialog-footer" style="white-space: nowrap">
        <!-- <el-button @click="handCopy">复制链接</el-button>
        <el-button type="primary" class="bg-blue-400" @click="handDownload">
          点击下载
        </el-button> -->
        <el-button
          v-for="data in parseInfo.down_item"
          :key="data.file_type"
          type="primary"
          class="bg-blue-400 mb-4 mr-4"
          @click="handParse1(data.file_type)"
        >
          {{ data.name }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="centerDialogVisible"
    title="解析成功"
    width="23%"
    align-center
  >
    <div class="mb-6 text-xl text-red-500">
      <span>《 解析成功 》 请点击《 立即下载 》按钮进行下载 </span>
    </div>
    <div class="mb-6">
      <span
        ><span class="text-blue-500">如果点击</span
        >下载按钮下载失败(或者出现403)
        <!-- <span class="text-xl text-red-500">下载失败(或者出现403)</span> -->
        按照下面方法操作:
      </span>
    </div>
    <!-- <span class="text-xl"
      >点击复制链接按钮,打开<span class="text-red-500">(新的标签页)</span
      >粘贴到地址栏进行下载
    </span> -->
    <span>点击复制链接按钮,打开(新的标签页)粘贴到地址栏进行下载 </span>
    <!-- <div class="mt-6">
      <span>（一定要打开浏览器新的标签页） </span>
    </div> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handCopy">复制下载链接手动下载</el-button>
        <!-- <el-button type="primary" class="bg-blue-400" @click="handDownload">
          点击下载
        </el-button> -->
        <a
          class="btn btn-danger"
          :href="parseInfo.downloadPath"
          target="_blank"
          rel="noreferrer noopener nofollow"
          >立即下载</a
        >
      </span>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.el-col {
  margin: 8px 0;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.btn-danger:hover {
  background-color: #c82333 !important; /* 鼠标悬停时的背景色 */
  border-color: #bd2130 !important; /* 鼠标悬停时的边框颜色 */
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
