<template >
  <div>
    <router-view></router-view>
  </div>
</template>


<style lang="scss">

</style>
